<template>
    <div class="mt-[40px] ">
        <p class="text-[35px] 2xl-1440:text-[20px] font-semibold mb-[12px] 2xl-1440:mb-[5px]">Datos alojamiento</p>
        <hr class="border-black border-[2px] mb-[16px] 2xl-1440:mb-[12px]" />
        <div class="grid grid-cols-4 gap-4">
            <!-- N° de habitaciones -->
            <div class="flex items-center bg-white border border-[#E9E9E9] rounded-full-style custom-shadow">
                <label class="input-label w-3/5 whitespace-nowrap">N° de habitaciones</label>
                <input type="number" v-model.number="form.rooms" class="input-field no-arrow text-right mr-4" />
            </div>

            <!-- Precio medio por noche -->
            <div class="flex items-center bg-white border border-[#E9E9E9] rounded-full-style custom-shadow">
                <label class="input-label wider-label">Precio medio por noche</label>
                <div class="flex items-center w-[45%] rounded-r-full">
                    <input type="number" v-model.number="form.averagePrice" step="0.01" class="input-field no-arrow text-right no-padding-right" />
                    <p class="input-suffix">€</p>
                </div>
            </div>

            <!-- Tasa de ocupación -->
            <div class="flex items-center bg-white border border-[#E9E9E9] rounded-full-style custom-shadow">
                <label class="input-label w-3/5 whitespace-nowrap">Tasa de ocupación</label>
                <div class="flex items-center w-[45%] rounded-r-full">
                    <input type="number" v-model.number="form.occupancyRate" step="0.01" class="input-field no-arrow text-right no-padding-right" />
                    <p class="input-suffix">%</p>
                </div>
            </div>

            <!-- Total de ingresos anuales -->
            <div class="flex items-center bg-white border-black border rounded-full-style ">
                <label class="input-label input-label-black-border wider-label">Total de ingresos anuales</label>
                <div class="flex items-center justify-end w-[45%] rounded-r-[10px] bg-[#FAFAFA] h-full">
                    <span class="input-field-total">{{ formattedAnnualRevenue }}</span>
                    <span class="input-suffix">€</span>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-[30px] ">
        <p class="text-[35px] 2xl-1440:text-[20px] font-semibold mb-[12px] 2xl-1440:mb-[5px]">Con TheHoster</p>
        <hr class="border-black border-[2px] mb-[12px] 2xl-1440:mb-[12px]" />
        <p class="text-[18px] font-semibold mb-[6px]">Ingresos</p>
        <div class="grid grid-cols-4 gap-4">
            <!-- Incremento en la reputación online -->
            <div class="flex items-center bg-white border border-[#E9E9E9] rounded-full-style custom-shadow">
                <label class="input-label wider-label-max-return whitespace-nowrap ">Incremento en la <br>reputación online</label>
                <input type="number" v-model.number="form.reputationIncrease" step="0.01" class="input-field no-arrow text-right no-padding-right" />
                <p class="input-suffix">%</p>
            </div>

            <!-- Incremento en la tasa de ocupación -->
            <div class="flex items-center bg-white border border-[#E9E9E9] rounded-full-style custom-shadow">
                <label class="input-label wider-label whitespace-nowrap">Incremento en la <br>tasa de ocupación</label>
                <div class="flex items-center rounded-r-full">
                    <input type="number" v-model.number="form.occupancyRateIncrease" step="0.01" class="input-field no-arrow text-right no-padding-right" />
                    <p class="input-suffix">%</p>
                </div>
            </div>

            <!-- Incremento en el precio medio por noche -->
            <div class="flex items-center bg-white border border-[#E9E9E9] rounded-full-style custom-shadow">
                <label class="input-label wider-label-max-increment whitespace-nowrap">Incremento en el <br>precio medio por noche</label>
                <div class="flex items-center rounded-r-full">
                    <input type="number" v-model.number="form.pricePerNightIncrease" step="0.01" class="input-field no-arrow text-right no-padding-right" />
                    <p class="input-suffix">%</p>
                </div>
            </div>

            <!-- Total de ingresos anuales con TheHoster -->
            <div class="flex items-center bg-white border-black border rounded-full-style">
                <label class="input-label input-label-black-border wider-label-max whitespace-nowrap">Ingresos anuales<br> con TheHoster</label>
                <div class="flex items-center justify-end w-[45%] rounded-r-[10px] bg-[#FAFAFA] h-full">
                    <span class="input-field-total">{{ formattedAnnualRevenueWithHoster }}</span>
                    <span class="input-suffix">€</span>
                </div>
            </div>
        </div>
        <p class="text-[18px] font-semibold mb-[6px] mt-[18px]">Costes</p>
        <div class="flex gap-4">
            <!-- Precio por habitación al mes -->
            <div class="flex items-center bg-white border border-[#E9E9E9] rounded-full-style custom-shadow w-[35%]">
                <label class="input-label wider-label whitespace-nowrap">Precio por habitación al mes</label>
                <div class="flex items-center w-[45%] rounded-r-full">
                    <input type="number" v-model.number="form.pricePerRoomPerMonth" step="0.01" class="input-field no-arrow text-right no-padding-right" />
                    <p class="input-suffix">€</p>
                </div>
            </div>

            <!--Precio de implementación-->
            <div class="flex items-center bg-white border border-[#E9E9E9] rounded-full-style custom-shadow w-[35%]">
                <label class="input-label wider-label whitespace-nowrap">Precio de implementación</label>
                <div class="flex items-center w-[45%] rounded-r-full">
                    <input type="number" v-model.number="form.implementationPrice" step="0.01" class="input-field no-arrow text-right no-padding-right" />
                    <p class="input-suffix">€</p>
                </div>
            </div>

            <!-- Inversión en TheHoster -->
            <div class="flex items-center bg-white border-black border rounded-full-style w-[30%]">
                <label class="input-label input-label-black-border wider-label-max whitespace-nowrap">Inversión en TheHoster</label>
                <div class="flex items-center justify-end w-[45%] rounded-r-[10px] bg-[#FAFAFA] h-full">
                    <span class="input-field-total">{{ formattedInvestmentInHoster }}</span>
                    <span class="input-suffix">€</span>
                </div>
            </div>
        </div>
        <div class="mt-[48px] 2xl-1440:mt-[38px]">
            <p class="text-[35px] 2xl-1440:text-[20px] font-semibold mb-[12px] 2xl-1440:mb-[5px]">Retorno</p>
            <hr class="border-black border-[2px] mb-[16px] 2xl-1440:mb-[12px]" />
            <div class="grid grid-cols-3 gap-8">
                <div class="flex items-center bg-white border-black border rounded-full-style">
                    <label class="input-label input-label-black-border wider-label-max-return">Incremento en la facturación</label>
                    <div class="flex items-center justify-end w-[45%] rounded-r-[9.5px] bg-[#34A98F] h-full">
                        <span class="input-field-total-return">+{{ formattedIncrement ?? 0 }}</span>
                        <span class="input-suffix-return">€</span>
                    </div>
                </div>

                <div class="flex items-center bg-white border-black border rounded-full-style">
                    <label class="input-label input-label-black-border wider-label-max-return">Beneficio</label>
                    <div class="flex items-center justify-end w-[45%] rounded-r-[9.2px] bg-[#34A98F] h-full">
                        <span class="input-field-total-return">+{{ formattedBenefit ?? 0 }}</span>
                        <span class="input-suffix-return">€</span>
                    </div>
                </div>

                <div class="flex items-center bg-white border-black border rounded-full-style">
                    <label class="input-label input-label-black-border wider-label-max-return">ROI</label>
                    <div class="flex items-center justify-end w-[45%] rounded-r-[9.2px] bg-[#34A98F] h-full">
                        <span class="input-field-total-return">{{ formattedROI?? 0 }}</span>
                        <span class="input-suffix-return">x</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>






<script setup>
import { reactive, computed, watch } from 'vue';

const form = reactive({
    rooms: '',
    averagePrice: null,
    occupancyRate: null,
    annualRevenue: '',
    // ingresos
    reputationIncrease: null,
    occupancyRateIncrease: null,
    pricePerNightIncrease: null,
    annualRevenueWithHoster: '',
    // costes
    pricePerRoomPerMonth: 8.99,
    implementationPrice: 900,
    investmentInHoster: 0, // Inicializado en 0
    benefit: 0 // Inicializado en 0
});

// Cálculo de "Total de ingresos anuales"
const annualRevenue = computed(() => {
    return Math.ceil(form.rooms * form.averagePrice * (form.occupancyRate / 100) * 365 || 0);
});

const formattedAnnualRevenue = computed(() => {
    return formatCurrency(annualRevenue.value);
});

// Cálculo de "Total de ingresos anuales con TheHoster"
const annualRevenueWithHoster = computed(() => {
    if (
        form.reputationIncrease !== null &&
        form.occupancyRateIncrease !== null &&
        form.pricePerNightIncrease !== null
    ) {
        return Math.ceil(form.rooms * (form.averagePrice * (1 + form.pricePerNightIncrease / 100)) * 365 * (form.occupancyRate * (1 + form.occupancyRateIncrease / 100) / 100) || 0);
    }
    return 0;
});

const formattedAnnualRevenueWithHoster = computed(() => {
    return formatCurrency(annualRevenueWithHoster.value);
});

// Cálculo de "Incremento en la facturación"
const increment = computed(() => {
    return Math.ceil(annualRevenueWithHoster.value - annualRevenue.value);
});

const formattedIncrement = computed(() => {
    return formatCurrency(increment.value);
});

// Watch para calcular la inversión en TheHoster solo cuando se cumpla la fórmula
/* watch(
    () => [form.rooms, form.pricePerRoomPerMonth, form.implementationPrice],
    ([rooms, pricePerRoomPerMonth, implementationPrice]) => {
        if (rooms && pricePerRoomPerMonth && implementationPrice) {
            form.investmentInHoster = Math.ceil(rooms * pricePerRoomPerMonth * 12 + implementationPrice);
        } else {
            form.investmentInHoster = 0;
        }
    },
    { immediate: true }
); */
watch(
    () => [form.rooms, form.pricePerRoomPerMonth, form.implementationPrice],
    ([rooms, pricePerRoomPerMonth, implementationPrice]) => {
        if (rooms && pricePerRoomPerMonth !== null) {
            form.investmentInHoster = Math.ceil(rooms * pricePerRoomPerMonth * 12 + (implementationPrice || 0));
        } else {
            form.investmentInHoster = 0;
        }
    },
    { immediate: true }
);

const formattedInvestmentInHoster = computed(() => {
    return formatCurrency(form.investmentInHoster);
});

// Cálculo del beneficio
const benefit = computed(() => {
    return Math.ceil(annualRevenueWithHoster.value - annualRevenue.value - form.investmentInHoster) || 0;
});

const formattedBenefit = computed(() => {
    return formatCurrency(benefit.value);
});

// Cálculo del ROI
const roi = computed(() => {
    const roiValue = ((annualRevenueWithHoster.value - annualRevenue.value - form.investmentInHoster) / form.investmentInHoster);
    if (isNaN(roiValue) || roiValue === Infinity || roiValue < 0) {
        return 0;
    }
    return roiValue;
});

const formattedROI = computed(() => {
    return roi.value.toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
});

// Formatear el número como moneda con puntos
function formatCurrency(value) {
    return value.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }); // Eliminando los decimales
}

// Usamos un watch para actualizar los valores en form
watch(annualRevenue, (newValue) => {
    form.annualRevenue = newValue;
});

watch(annualRevenueWithHoster, (newValue) => {
    form.annualRevenueWithHoster = newValue;
});

watch(benefit, (newValue) => {
    form.benefit = newValue;
});




</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Rethink+Sans:wght@400;700&display=swap');
/* Eliminar las flechas del input de tipo número */
.no-arrow::-webkit-inner-spin-button,
.no-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
label,span,p {
  font-family: 'Rethink Sans', sans-serif !important;
}


.no-arrow {
  -moz-appearance: textfield;
}

/* Sombra personalizada */
.custom-shadow {
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 1px 0px rgba(0, 0, 0, 0.04);
}

/* Redondeo completo aplicado en el contenedor */
.rounded-full-style {
    border-radius: 10px;
}


/* Estilo general para los labels y los inputs */
.input-label {
    padding: 15px 15px;
    font-size: 15px;
    font-weight: 600;
    color: black;
    text-align: left;
    border-right: 1px solid #E9E9E9;
    border-radius: 9999px 0 0 9999px;
}

.input-label-black-border {
    border-right: 1px solid black !important;
}

/* Clase específica para labels más anchos */
.wider-label {
    width: 56%; /* Ajusta este ancho según lo necesites */
}

.wider-label-max {
    width: 60%; /* Ajusta este ancho según lo necesites */
}

.wider-label-max-return {
    width: 80%; /* Ajusta este ancho según lo necesites */
}

.wider-label-max-increment {
    width: 80%; /* Ajusta este ancho según lo necesites */
}

/* Asegurar que el label no se rompa en varias líneas */
.whitespace-nowrap {
    white-space: nowrap;
}

.border-bottom-custom {
    border-bottom: 4px solid #34A98F;
}

.input-field {
    padding: 15px 0; /* Eliminado el padding derecho */
    font-size: 18px;
    font-weight: 600;
    line-height: 130%;
    width: 100%;
    border-radius: 0 9999px 9999px 0;
    border: none;
    text-align: right;
}

.input-field-total {
    font-size: 20px;
    font-weight: 500;
    line-height: 130%;
    width: 100%;
    text-align: right;
    color: black;
}

.input-field-total-return {
    font-size: 20px;
    font-weight: 500;
    line-height: 130%;
    width: 100%;
    text-align: right;
    color: white;
}

/* Clase adicional para eliminar el padding derecho */
.no-padding-right {
    padding-right: 0;
}

.input-suffix {
    padding-left: 12px;
    padding-right: 8px;
    font-size: 20px;
    font-weight: 600;
    margin-left: -12px; /* Ajuste para compensar el padding del input */
    color: black;
}

.input-suffix-return {
    padding-left: 12px;
    padding-right: 8px;
    font-size: 20px;
    font-weight: 600;
    margin-left: -12px; /* Ajuste para compensar el padding del input */
    color: white;
}

/* Eliminar el borde y sombra en el focus del input */
input:focus {
    border: none !important;
    box-shadow: none !important;
}
</style>

