<template>
  <div
    class="relative w-full h-screen overflow-hidden bg-[#FFFFFF] rethink-sans"
    @wheel="handleScroll"
  >
    <!-- Header con el título a la izquierda y el logo a la derecha -->
    <div v-if="currentSlide && currentSlide.header" class="absolute top-0 left-0 w-full z-20 bg-[#FFFFFF] pr-20 pl-20 rethink-sans">
      <div class="flex justify-between items-center pt-[24px] pb-[24px]  pr-[40px]">
        <h2 class="text-[24px] font-normal rethink-sans">{{ currentSlide.title }}</h2>
        <img src="/img/1.TH.logo.svg" alt="Logo" class="h-[29px] object-contain" />
      </div>
      <hr class="border-gray-300 pl-[40px] pr-[40px]" />
    </div>

    <!-- Diapositiva fija con el logo central -->
    <div
      v-if="currentIndex === 0"
      class="absolute inset-0 flex flex-col justify-start transition-opacity duration-500 ease-in-out opacity-100 bg-[#FFFFFF] rethink-sans"
    >
      <div class="flex-grow flex items-center justify-center">
        <img src="/img/presentacion/1.TH.logo.png" alt="Logo Central" class="max-w-full max-h-[110px] object-contain" />
      </div>
    </div>

    <!-- Diapositiva dinámica con transición -->
    <transition
      :name="transitionName"
      mode="out-in"
    >
      <div
        v-if="currentSlide"
        :key="currentSlide.name"
        class="absolute inset-0 flex flex-col justify-start bg-[#FFFFFF] pr-20 pl-20 rethink-sans"
      >
        <!-- Contenido del slide -->
        <div
          :class="{
            'flex-col': currentSlide.layout === 'col',
            'flex-row': currentSlide.layout === 'row'
          }"
          class="flex pb-10 pt-[80px] rethink-sans" 
        >
          <slot :name="currentSlide.name"></slot>
        </div>
      </div>
    </transition>

    <!-- Controles de navegación con flechas -->
    <div v-if="showButtons" class="absolute bottom-4 right-4 flex z-10 rethink-sans">
      <button
        @click="prevSlide"
        :class="[isFirstSlide ? 'disabled-button' : 'button-active', 'p-2 rounded-l-lg border-none focus:outline-none']"
        :disabled="isFirstSlide"
      >
        <img src="/img/presentacion/up.svg" alt="Up" class="w-8 h-8" />
      </button>
      <button
        @click="nextSlide"
        :class="[isLastSlide ? 'disabled-button' : 'button-active', 'p-2 rounded-r-lg border-none focus:outline-none']"
        :disabled="isLastSlide"
      >
        <img src="/img/presentacion/down.svg" alt="Down" class="w-8 h-8" />
      </button>
    </div>

  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';

const props = defineProps({
  useScroll: {
    type: Boolean,
    default: false,
  },
  showButtons: {
    type: Boolean,
    default: true,
  }
});

const currentIndex = ref(0);
const transitionName = ref('slide-up-down');

// Arreglo de diapositivas dinámicas
const dynamicSlides = [
  { name: 'slide-1', header: true, layout: 'col', title: '¿QUÉ HACEMOS?' },
  { name: 'slide-2', header: true, layout: 'col', title: '¿CÓMO LO HACEMOS?' },
  { name: 'slide-3', header: true, layout: 'col', title: 'RETORNO' },
  { name: 'slide-4', header: true, layout: 'col', title: 'PRICING' },
  { name: 'slide-5', header: true, layout: 'col', title: 'CALCULADORA DE BENEFICIO' },
];

const isFirstSlide = computed(() => currentIndex.value === 0);
const isLastSlide = computed(() => currentIndex.value === dynamicSlides.length);


// Computed property to get the current slide based on the currentIndex
const currentSlide = computed(() => {
  return dynamicSlides[currentIndex.value - 1] || null;
});

const prevSlide = () => {
  if (currentIndex.value > 0) {
    transitionName.value = 'slide-up-down';
    currentIndex.value--;
  }
};

const nextSlide = () => {
  if (currentIndex.value < dynamicSlides.length) {
    transitionName.value = 'slide-up-down';
    currentIndex.value++;
  }
};

let isScrolling = false;

const handleScroll = (event) => {
  if (props.useScroll && !isScrolling) {
    isScrolling = true;
    if (event.deltaY < 0) {
      prevSlide();
    } else if (event.deltaY > 0) {
      nextSlide();
    }
    setTimeout(() => {
      isScrolling = false;
    }, 620);
  }
};

const handleKeydown = (event) => {
  if (event.key === 'ArrowUp') {
    prevSlide();
  } else if (event.key === 'ArrowDown') {
    nextSlide();
  }
};

onMounted(() => {
  window.addEventListener('keydown', handleKeydown);
});

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeydown);
});
</script>

<style scoped>
.rethink-sans {
  font-family: 'Rethink Sans', sans-serif !important;
}

.slide-up-down-enter-active, .slide-up-down-leave-active {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
.slide-up-down-enter-from {
  transform: translateY(100%);
  opacity: 0;
}
.slide-up-down-enter-to {
  transform: translateY(0);
  opacity: 1;
}
.slide-up-down-leave-from {
  transform: translateY(0);
  opacity: 1;
}
.slide-up-down-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

/* .button-active {
  background-color: white;
  cursor: pointer;
  opacity: 1;
}

.button-active:hover {
  background-color: #8D8D8D;
  opacity: 0.2;
}


.button-active:hover img {
  filter: brightness(0) invert(0);
}
.disabled-button {
  opacity: 0.5;
  cursor: not-allowed;
} */

/* --- */
.button-active {
  position: relative;
  background-color: white;
  cursor: pointer;
  overflow: hidden; /* Asegura que el pseudo-elemento no se salga del botón */
}

.button-active:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #8D8D8D;
  opacity: 0.5;
  z-index: 1; /* Coloca la capa de opacidad detrás del contenido */
}

.button-active:hover img {
  filter: brightness(0); /* Cambia el icono a color negro */
  z-index: 2; /* Asegura que el icono esté sobre la capa de opacidad */
}

.button-active img {
  position: relative; /* Asegura que el icono esté sobre el pseudo-elemento */
  z-index: 2;
}

.disabled-button {
  opacity: 0.5;
  cursor: not-allowed;
}


</style>
