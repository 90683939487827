<template>
    <div class="relative w-full h-screen overflow-hidden bg-black">
      <div
        v-for="(slide, index) in slides"
        :key="index"
        class="absolute inset-0 flex items-center justify-center transition-opacity duration-500 ease-in-out"
        :class="{ 'opacity-100': currentIndex === index, 'opacity-0': currentIndex !== index }"
      >
        <!-- Primera diapositiva con imagen centrada -->
        <div v-if="index === 0" class="flex items-center justify-center h-full">
          <img :src="slide.image" alt="Logo" class="max-w-full max-h-full object-contain" />
        </div>
  
        <!-- Otras diapositivas con contenido variado -->
        <div v-else class="text-center text-white p-6">
          <img :src="slide.image" alt="Slide Image" class="mx-auto mb-6 rounded-lg shadow-lg max-w-full max-h-96 object-cover" />
          <h1 class="text-4xl font-bold">{{ slide.title }}</h1>
          <p class="mt-4 text-lg">{{ slide.content }}</p>
        </div>
      </div>
      <div class="absolute bottom-8 w-full flex justify-between px-4">
        <button
          @click="prevSlide"
          class="bg-gray-800 text-white px-4 py-2 rounded-lg hover:bg-gray-700 focus:outline-none"
        >
          Anterior
        </button>
        <button
          @click="nextSlide"
          class="bg-gray-800 text-white px-4 py-2 rounded-lg hover:bg-gray-700 focus:outline-none"
        >
          Siguiente
        </button>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  
  const slides = ref([
    {
      title: 'Slide 1',
      content: 'Este es el contenido de la primera diapositiva.',
      image: '/img/1.TH.logo.svg', // Imagen proporcionada por ti
    },
    {
      title: 'Slide 2',
      content: 'Este es el contenido de la segunda diapositiva.',
      image: 'https://via.placeholder.com/1600x900.png?text=Image+2', // Imagen de ejemplo
    },
    {
      title: 'Slide 3',
      content: 'Este es el contenido de la tercera diapositiva.',
      image: 'https://via.placeholder.com/1600x900.png?text=Image+3', // Imagen de ejemplo
    },
  ]);
  
  const currentIndex = ref(0);
  
  const prevSlide = () => {
    if (currentIndex.value > 0) {
      currentIndex.value--;
    } else {
      currentIndex.value = slides.value.length - 1;
    }
  };
  
  const nextSlide = () => {
    if (currentIndex.value < slides.value.length - 1) {
      currentIndex.value++;
    } else {
      currentIndex.value = 0;
    }
  };
  </script>
  
  <style scoped>
  /* No es necesario incluir estilos adicionales con Tailwind */
  </style>
  