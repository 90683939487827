import DirectoryPage from '@/views/landings/DirectoryPage.vue';
import ExperiencesPage from '@/views/landings/ExperiencesPage.vue';
import DestinyPage from '@/views/landings/DestinyPage.vue';
import ChatPage from '@/views/landings/ChatPage.vue';
import CapturingReviews from '@/views/landings/CapturingReviews.vue';
import ConsoleReviews from '@/views/landings/ConsoleReviews.vue';
import FirewallPage from '@/views/landings/FirewallPage.vue';
import AnalysisPage from '@/views/landings/AnalysisPage.vue';
import HosterPage from '@/views/landings/HosterPage.vue';
import GuestPage from '@/views/landings/GuestPage.vue';
import ComunicationPage from '@/views/landings/ComunicationPage.vue';
import ReputationPage from '@/views/landings/ReputationPage.vue';
import SliderPage from '@/views/landings/SliderPage.vue';
import Calc from '@/views/landings/components/slider/PriceSlider.vue';

const adminRoutes = [
  //product pages
  //
  {
    path: '/directorio',
    name: 'directory',
    component: DirectoryPage
  },
  {
    path: '/experiencias',
    name: 'experiences',
    component: ExperiencesPage
  },
  {
    path: '/destino',
    name: 'destiny',
    component: DestinyPage
  },
  {
    path: '/chat',
    name: 'chat',
    component: ChatPage
  },
  {
    path: '/captacion-de-resenas',
    name: 'capturing_reviews',
    component: CapturingReviews
  },
  {
    path: '/consola-de-resenas',
    name: 'console_reviews',
    component: ConsoleReviews
  },
  {
    path: '/cortafuegos',
    name: 'firewall',
    component: FirewallPage
  },
  {
    path: '/analisis',
    name: 'analysis',
    component: AnalysisPage
  },
  //solutions pages
  //
  {
    path: '/hoster',
    name: 'hoster',
    component: HosterPage
  },
  {
    path: '/huesped',
    name: 'guest',
    component: GuestPage
  },
  {
    path: '/comunicacion',
    name: 'comunication',
    component: ComunicationPage
  },
  {
    path: '/reputacion',
    name: 'reputation',
    component: ReputationPage
  },
  {
    path: '/presentacion',
    name: 'slider',
    component: SliderPage
  },
  {
    path: '/calculadora',
    name: 'calculator',
    component: Calc
    
  }
];

export default adminRoutes;
