import { createRouter, createWebHistory } from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import landingRoutes from './landings';
import legalRoutes from './legal';

import HomePage from '@/views/HomePage.vue';
import ContactPage from '@/views/ContactPage.vue';
import MailsTestPage from '@/views/tests/MailBuilder.vue';

const routes = [
  {
    path: '/',
    name: 'homePage',
    component: HomePage
  },
  {
    path: '/contacto',
    name: 'contact',
    component: ContactPage
  },
  {
    path: '/mailbuilder',
    name: 'mailbuilder',
    component: MailsTestPage
  },
  ...landingRoutes,
  ...legalRoutes,
];

const router = createRouter({
  history: createWebHistory('/'),
  routes
});
router.beforeEach((to, from, next) => {
  NProgress.start();
  next();
});
router.afterEach(() => {
  window.scrollTo(0, 0);
  NProgress.done();
});

export default router;

